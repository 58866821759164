import axios from "axios";
import set from "lodash/set";
import { useEffect, useState } from "react";

export function useShareDB(id, sharer) {
  const [data, setData] = useState([]);
  const [err, setErr] = useState();
  const [loading, setLoading] = useState(true);

  async function join() {
    console.log("loading", id);
    await sharer.join("flows", id, {
      id,
      edges: [],
      nodes: {}
    });
    try {
      const { data } = sharer.doc;
      setData(data);
    } catch (e) {
      setErr(e);
    }
    setLoading(false);
  }

  useEffect(() => {
    join();
  }, [id]);

  return [data, loading, err];
}

export function useApi(
  endpoint: string,
  method = "get",
  root = process.env.API_URL
) {
  const [data, setData] = useState([]);
  const [err, setErr] = useState();
  const [loading, setLoading] = useState(true);

  async function fetchUrl() {
    const url = [root, endpoint].join("/");
    console.log(`fetching ${url}`);
    try {
      const { data } = await axios[method](url);
      setData(data);
    } catch (e) {
      setErr(e);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchUrl();
  }, []);
  return [data, loading, err];
}

export const useForm = (initialState, callback: any) => {
  const [values, setValues] = useState(initialState);

  const handleSubmit = event => {
    if (event) event.preventDefault();
    // console.log({ values });
    callback(values);
  };

  const handleChange = event => {
    event.persist();

    setValues(values =>
      set({ ...values }, event.target.name, event.target.value)
    );
  };

  const setFieldValue = (field: string, value: any) => {
    setValues(values => set({ ...values }, field, value));
  };

  return {
    setFieldValue,
    handleChange,
    handleSubmit,
    values,
    setValues
  };
};

export const useFetch = (url, options) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(url, options);
        const json = await res.json();
        setResponse(json);
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
  }, []);
  return { response, error };
};

export const useTitle = (titleOrFn: string | Function, ...deps) => {
  useEffect(() => {
    document.title = typeof titleOrFn === "string" ? titleOrFn : titleOrFn();
    document.title += " - PlanX";
  }, [...deps]);
};
