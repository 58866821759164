import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import LockIcon from "@material-ui/icons/LockOutlined";
import GoogleIcon from "mdi-material-ui/GooglePlus";
import MicrosoftIcon from "mdi-material-ui/Microsoft";
import * as React from "react";
import { useTitle } from "../lib/hooks";

const styles = theme => ({
  paper: {
    maxWidth: 400,
    margin: `${theme.spacing(8)}px auto 0`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    marginTop: theme.spacing(),
    maxWidth: "100%"
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  errors: {
    color: "red"
  },

  rightIcon: {
    marginLeft: theme.spacing(1)
  }
});

const useStyles = makeStyles(styles as any);

const OAuthButton = ({
  text,
  url,
  icon
}: {
  text: string;
  icon;
  url?: string;
}) => {
  const classes = useStyles();
  const Icon = icon;
  const fullUrl = `${process.env.API_URL}/auth/${url}`;

  return (
    <Button
      type="submit"
      fullWidth={false}
      variant="contained"
      color="primary"
      className={classes.submit}
      disabled={!url}
      onClick={() => url && (window.location.href = fullUrl)}
    >
      {text}
      <Icon className={classes.rightIcon} />
    </Button>
  );
};

const OAuth = () => {
  const classes = useStyles();
  useTitle("Sign in");

  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <Typography variant="h4">Sign in</Typography>

        <OAuthButton text="use Google" icon={GoogleIcon} url="google" />
        <OAuthButton
          text="use Microsoft Azure"
          icon={MicrosoftIcon}
          url="azure"
        />
      </Paper>
    </main>
  );
};

export default OAuth;
