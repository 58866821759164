import * as React from "react";
import { render } from "react-dom";
import OAuth from "./components/OAuth";
import MAPPINGS from "./data/mappings";
import { uuid } from "./lib/guid";
import { useFetch } from "./lib/hooks";

const AuthenticatedApp = React.lazy(() =>
  import("./components/AuthenticatedApp")
);

const App = () => {
  const { response, error } = useFetch(
    `${process.env.API_URL}/auth/login/success`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true
      }
    }
  );

  if (response && process.env.LOCAL) {
    response.user = {
      _json: {
        name: "kevin (local)",
        email: "kevin@wycombe.gov.uk"
      }
    };
  }

  if (error) {
    return <h1>Error</h1>;
  } else if (response && response.user) {
    const mapping = MAPPINGS[response.user._json.email.split("@").pop()];

    return (
      <React.Suspense fallback={<div>Logging in...</div>}>
        <AuthenticatedApp
          user={response.user}
          id={mapping ? mapping.flow : uuid()}
          mapping={mapping}
        />
      </React.Suspense>
    );
  } else {
    return <OAuth />;
  }
};

render(<App />, document.getElementById("main"));
