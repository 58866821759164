export default {
  "wikihouse.cc": {
    team: "osl",
    flow: "4a7c91bf-690c-480c-b60b-4d1302c314e1"
  },
  "opensystemslab.io": {
    team: "osl",
    flow: "4a7c91bf-690c-480c-b60b-4d1302c314e1"
  },
  "wycombe.gov.uk": {
    team: "wycombe",
    flow: "4752fb1e-d461-4c5b-9e85-85cef6c70683"
  }
};
